interface HasMessage {
  message: string;
}

export const hasMessage = (obj: unknown): obj is HasMessage => {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'message' in obj &&
    typeof (obj as HasMessage).message === 'string'
  );
};
